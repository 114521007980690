;(function($){

    function Colorpick() {
   	 	$('#color').colorpicker();
    }

   	new Colorpick();

}(jQuery));
 
 
 
 
