;(function($){

    function Mask() {
   	 	$('[name=phone]').mask('(00) 0 0000-0000');
        $('[name=cellphone]').mask('(00) 00000-0000');
        $('[name=cpf]').mask('999.999.999-99');
        $("[name=cnpj]").mask("99.999.999/9999-99");
        $("[name=postal_code]").mask("99999-999");
        $('[data-money]').mask('000.000.000.000.000,00', {reverse: true});
        $('[data-integrate]').mask('0#');
        $('[data-percentage]').mask('##0.00', {reverse: true});
        $('[data-cref]').mask('999999-A/AA');


        var SPMaskBehavior = function (val) {
          return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
          onKeyPress: function(val, e, field, options) {
              field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };



        // $( "p" ).focusout(function() {
        //     focus++;
        //     $( "#focus-count" ).text( "focusout fired: " + focus + "x" );
        //   });
        $('[name=cnpj_cpf]').focusout(function() {
            var tamanho = $(this).val().length;

            if(tamanho == 0)
            {
                console.log("aqui");
                 try {
                    $(this).unmask("999.999.999-99");
                    $(this).unmask("99.999.999/9999-99");
                } catch (e) {}
            }
            
            if(tamanho < 12){
                $(this).mask("999.999.999-99");
            } else {
                $(this).mask("99.999.999/9999-99");
            } 
        });

    }

   	new Mask();

}(jQuery));
 
 
 
 
